import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useState } from "react";
import { Block, Button, FormGroup, Modal, Text, TextInput } from '../../../../../../components';
import colors from "../../../../../../utils/colors";

export default function ModalSubscription({ clientId, isOpen, onUpdate, onRequestClose }) {
    const [option, setOption] = useState(null);
    const [numberOfDays, setNumberOfDays] = useState(0);
    const [numberOfEm, setNumberOfEm] = useState(0);

    const [mutateClientAddCredits, { loading: loadingClientAddCredits }] = useMutation(
        gql`
            mutation clientAddCredits($id: ID!, $value: Int!) {
                clientAddCredits(id: $id, value: $value)
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.clientAddCredits) {
                    setNumberOfDays(0);
                    setNumberOfEm(0);
                    onUpdate();
                    onRequestClose();
                }
            }
        }
    );

    const [mutateClientAddPremiumDays, { loading: loadingClientAddPremiumDays }] = useMutation(
        gql`
            mutation clientAddPremiumDays($id: ID!, $value: Int!) {
                clientAddPremiumDays(id: $id, value: $value)
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.clientAddPremiumDays) {
                    setNumberOfDays(0);
                    setNumberOfEm(0);
                    onUpdate();
                    onRequestClose();
                }
            }
        }
    );

    const [mutateClientCancelSubscription, { loading: loadingClientCancelSubscription }] = useMutation(
        gql`
            mutation clientCancelSubscription($id: ID!) {
                clientCancelSubscription(id: $id)
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.clientCancelSubscription) {
                    setNumberOfDays(0);
                    setNumberOfEm(0);
                    onUpdate();
                    onRequestClose();
                }
            }
        }
    );

    const doIt = () => {
        switch (option) {
            case "subscription": mutateClientAddPremiumDays({ variables: { id: clientId, value: parseInt(numberOfDays) } }); break;
            case "credits": mutateClientAddCredits({ variables: { id: clientId, value: parseInt(numberOfEm) } }); break;
            case "cancel": mutateClientCancelSubscription({ variables: { id: clientId } }); break;
            default: break;
        }
    }

    return (
        <Modal
            title="Manage Client Subscription"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
        >
            <Block>
                <FormGroup>
                    <Text size={12} height={16} color={colors.grey03}>Options</Text>
                    <Block width={"100%"} height={1} color={colors.grey05} />
                    <Block row center space="between">
                        <Block row center>
                            <input id="option-subscription" type="radio" name="option" value="subscription" onChange={(e) => { if (e.target.checked) setOption("subscription") }} />
                            <label htmlFor="option-subscription"><Text size={14} height={20} color={colors.grey01} ml={12}>Free premium days</Text></label>
                        </Block>
                        <Block width={156}>
                            <TextInput type="number" name="numberOfDays" label="Number of days" value={numberOfDays} onChangeValue={setNumberOfDays} />
                        </Block>
                    </Block>
                    <Block width={"100%"} height={1} color={colors.grey05} />
                    <Block row center space="between">
                        <Block row center>
                            <input id="option-credits" type="radio" name="option" value="credits" onChange={(e) => { if (e.target.checked) setOption("credits") }} />
                            <label htmlFor="option-credits"><Text size={14} height={20} color={colors.grey01} ml={12}>Free credits</Text></label>
                        </Block>
                        <Block width={156}>
                            <TextInput type="number" name="numberOfEm" label="Number of EM" value={numberOfEm} onChangeValue={setNumberOfEm} />
                        </Block>
                    </Block>
                    <Block width={"100%"} height={1} color={colors.grey05} />
                    <Block row center space="between" height={52}>
                        <Block row center>
                            <input id="option-cancel" type="radio" name="option" value="cancel" onChange={(e) => { if (e.target.checked) setOption("cancel") }} />
                            <label htmlFor="option-cancel"><Text size={14} height={20} color={colors.grey01} ml={12}>Cancel Subscription</Text></label>
                        </Block>
                    </Block>
                </FormGroup>
            </Block>
            <Block width={"100%"} height={1} color={colors.grey05} mt={12} mb={24} />
            <Block>
                <Button label="Submit changes" color={colors.grey01} loading={loadingClientAddCredits || loadingClientAddPremiumDays || loadingClientCancelSubscription} onClick={doIt} />
            </Block>
        </Modal>
    );
}