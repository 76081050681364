import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { AdvancedTable, Block, Icon, SearchBar, Text } from '../../components';
import colors from '../../utils/colors';
import ModalUpdate from './components/ModalUpdate';

function Pages() {
    const [pages, setPages] = useState({ edges: [] });
    const [search, setSearch] = useState("");
    const [modalPageId, setModalPageId] = useState(null);
    const [isModalPageOpen, setIsModalPageOpen] = useState(false);

    const paginate = useRef(false);

    const [queryPages, { loading: loadingPages }] = useLazyQuery(
        gql`
            query pages($first: Int, $after: String, $search: String) {
                pages(first: $first, after: $after, search: $search) {
                    edges {
                        cursor
                        node {
                            id
                            title
                            slug
                        }
                    }
                    pageInfo {
                        startCursor
                        endCursor
                        hasNextPage
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.pages) {
                    setPages({
                        edges: paginate.current ? [...pages.edges, ...data.pages.edges] : data.pages.edges,
                        pageInfo: data.pages.pageInfo
                    });
                    paginate.current = false;
                }
            }
        }
    );

    const requestPages = (params = { next: false }) => {
        const { first, after, next } = params;

        if (next) {
            paginate.current = true;
        }

        queryPages({
            variables: {
                first: first || 20,
                after: after || undefined,
                search: search !== "" ? search : undefined
            }
        });
    }

    useEffect(() => {
        requestPages();
    }, []);

    useEffect(() => {
        requestPages();
    }, [search]);

    return (
        <>
            <Block height="100%" style={{ overflow: "hidden" }}>
                <SearchBar value={search} onChange={setSearch} />
                <Container flex style={{ overflow: "hidden" }}>
                    <Block height="calc(100% - 56px)" pt={24}>
                        <AdvancedTable
                            emptyLabel="No pages"
                            columns={[
                                {
                                    label: "Title",
                                    value: "title",
                                },
                                {
                                    label: "Slug",
                                    value: "slug"
                                },
                                {
                                    label: "",
                                    value: "actions"
                                }
                            ]}
                            rows={pages.edges.map((edge) => {
                                return ({
                                    title: edge.node.title,
                                    slug: edge.node.slug,
                                    actions: <Block row right mr={-16} pointer>
                                        <Block row height={52} pl={16} pr={16} center style={{ borderLeft: `1px solid ${colors.grey05}` }} onClick={() => { setIsModalPageOpen(true); setModalPageId(edge.node.id); }}>
                                            <Icon name="file_text_16px" />
                                            <Text color={colors.grey01} size={14} height={20} ml={8}>Edit</Text>
                                        </Block>
                                    </Block>
                                });
                            })}
                        />
                    </Block>
                </Container>
            </Block>

            <ModalUpdate
                pageId={modalPageId}
                isOpen={isModalPageOpen}
                onRequestClose={() => {
                    setIsModalPageOpen(false);
                    setModalPageId(null);
                }}
                onUpdate={(page) => {
                    const index = pages.edges.findIndex((edge) => edge.node.id === page.id);
                    pages.edges[index].node = { ...pages.edges[index].node, ...page };
                    setPages(pages);
                }}
            />
        </>
    );
}

const Container = styled(Block)`
    padding: 32px 20px;
`;

export default Pages;
