import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import gql from 'graphql-tag';
import React, { useEffect, useRef, useState } from "react";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import styled from "styled-components";
import * as yup from 'yup';
import { AdvancedTable, Block, Button, Form, FormGroup, Icon, Input, Portlet, Text, TextInput } from '../../../../components';
import colors from "../../../../utils/colors";
import ModalSubscription from "./components/ModalSubscription";

export default function ModalUpdate({ clientId, isOpen, onRequestClose, onUpdate }) {
    const [client, setClient] = useState(null);

    const paginate = useRef(false);
    const formEditAccountRef = useRef();
    const formEditBillingRef = useRef();

    const [receipts, setReceipts] = useState({ edges: [], pageInfo: { hasPreviousPage: false, hasNextPage: false } });
    const [isManageSubscriptionModalOpen, setIsManageSubscriptionModalOpen] = useState(false);

    const [queryClient, { loading: loadingClient }] = useLazyQuery(
        gql`
            query client($id: ID!) {
                client(id: $id) {
                    id
                    completeName
                    company
                    name
                    address
                    city
                    postalCode
                    nif
                    email
                    subscription {
                        id
                        interval
                        purchaseDate
                        renovationDate
                        duration
                        remaining
                    }
                    stats {
                        sent
                        completed
                        available
                        used
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.client) {
                    setClient(data.client);
                }
            }
        }
    );

    const [queryReceipts, { loading: loadingReceipts }] = useLazyQuery(
        gql`
            query receipts($client: ID!, $first: Int, $after: String) {
                receipts(client: $client, first: $first, after: $after) {
                    edges {
                        cursor
                        node {
                            id
                            receiptId
                            receiptUrl
                            receiptDate
                        }
                    }
                    pageInfo {
                        startCursor
                        endCursor
                        hasPreviousPage
                        hasNextPage
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.receipts) {
                    setReceipts({
                        edges: paginate.current ? [...receipts.edges, ...data.receipts.edges] : data.receipts.edges,
                        pageInfo: data.receipts.pageInfo
                    });
                    paginate.current = false;
                }
            }
        }
    );

    const [mutateUserUpdateAccount, { loading: loadingUserUpdateAccount }] = useMutation(
        gql`
            mutation clientUpdate($id: ID!, $completeName: String, $company: String, $email: String, $password: String) {
                clientUpdate(id: $id, completeName: $completeName, company: $company, email: $email, password: $password) {
                    id
                    completeName
                    company
                    name
                    address
                    city
                    postalCode
                    nif
                    email
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.clientUpdate) {
                    !!onUpdate && onUpdate(data.clientUpdate);
                    setClient({ ...client, ...data.clientUpdate });
                }
            }
        }
    );

    const [mutateUserUpdateBilling, { loading: loadingUserUpdateBilling }] = useMutation(
        gql`
            mutation clientUpdate($id: ID!, $name: String, $address: String, $city: String, $postalCode: String, $nif: String) {
                clientUpdate(id: $id, name: $name, address: $address, city: $city, postalCode: $postalCode, nif: $nif) {
                    id
                    completeName
                    company
                    name
                    address
                    city
                    postalCode
                    nif
                    email
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.clientUpdate) {
                    !!onUpdate && onUpdate(data.clientUpdate);
                    setClient({ ...client, ...data.clientUpdate });
                }
            }
        }
    );

    const requestReceipts = (params = { next: false }) => {
        let { first, after, next } = params;

        if (next) {
            paginate.current = true;
            after = receipts.edges[receipts.edges.length - 1].cursor
        }

        queryReceipts({
            variables: {
                client: clientId,
                first: first || 5,
                after: after || undefined,
            }
        });
    }

    useEffect(() => {
        if (!isOpen) {
            setClient(null);
            setReceipts({ edges: [], pageInfo: { hasPreviousPage: false, hasNextPage: false } });
        }
    }, [isOpen]);

    useEffect(() => {
        if (!!clientId) {
            queryClient({ variables: { id: clientId } });
            requestReceipts();
        }
    }, [clientId]);

    return (
        <>
            <SlidingPane
                className="sliding-modal"
                overlayClassName="sliding-modal-overlay"
                hideHeader={true}
                onRequestClose={onRequestClose}
                width={"100%"}
                isOpen={isOpen}
            >
                <ButtonBack center middle pointer onClick={onRequestClose}>
                    <Icon name="arrow_left_20px" />
                </ButtonBack>
                <Block pt={64} pl={230} pr={230} pb={195} color={colors.grey05}>
                    {!!client && <>
                        <Block pb={48}>
                            <Text size={40} height={48} color={colors.grey01}>Client {client.completeName}</Text>
                        </Block>
                        <Block row wrap ml={-10} mr={-10}>
                            <Block ml={10} mr={10} mb={16} width="calc(50% - 20px)">
                                <Portlet
                                    width="100%"
                                    height="325px"
                                >
                                    <Block>
                                        <Text size={16} height={24} color={colors.grey01}>Evidence Match</Text>
                                        <Text size={12} height={20} color={colors.grey03} mt={20}>Remaining</Text>
                                        <Text size={40} height={48} color={colors.grey01}>{!!client.subscription ? "Unlimited" : client.stats.available}</Text>
                                    </Block>
                                    <Block row mt={8}>
                                        <Block mr={52}>
                                            <Text size={12} height={20} color={colors.grey03}>Sent</Text>
                                            <Text size={32} height={36} color={colors.grey01}>{client.stats.sent}</Text>
                                        </Block>
                                        <Block mr={52}>
                                            <Text size={12} height={20} color={colors.grey03}>Completed</Text>
                                            <Text size={32} height={36} color={colors.grey01}>{client.stats.completed}</Text>
                                        </Block>
                                        <Block>
                                            <Text size={12} height={20} color={colors.grey03}>Used</Text>
                                            <Text size={32} height={36} color={colors.grey01}>{client.stats.used}</Text>
                                        </Block>
                                    </Block>
                                </Portlet>
                            </Block>
                            <Block ml={10} mr={10} mb={16} width="calc(50% - 20px)">
                                <Portlet
                                    width="100%"
                                    height="224px"
                                    actions={
                                        <Block row>
                                            <Block>
                                                <Button color={colors.yellow01} onClick={() => setIsManageSubscriptionModalOpen(true)}>Manage Subscription</Button>
                                            </Block>
                                        </Block>
                                    }
                                >
                                    <Block>
                                        <Text size={16} height={24} color={colors.grey01}>Subscription</Text>
                                    </Block>
                                    <Block row mt={20}>
                                        <Block mr={30}>
                                            <Text size={12} height={20} color={colors.grey03}>Type of subscription</Text>
                                            <Text size={12} height={20} color={colors.grey01}>{!!client.subscription ? "Unlimited Evidence Match" : "No Subscription"}</Text>
                                        </Block>
                                        <Block mr={30}>
                                            <Text size={12} height={20} color={colors.grey03}>Purchase date</Text>
                                            <Text size={12} height={20} color={colors.grey01}>{!!client.subscription ? client.subscription.purchaseDate : "--"}</Text>
                                        </Block>
                                        <Block>
                                            <Text size={12} height={20} color={colors.grey03}>Duration</Text>
                                            <Text size={12} height={20} color={colors.grey01}>{!!client.subscription ? client.subscription.duration + " day(s)" : "--"}</Text>
                                        </Block>
                                    </Block>
                                    <Block mt={28}>
                                        <Block row mb={8}>
                                            <Text size={12} height={20} color={colors.grey03}>Limit date:</Text>
                                            <Text size={12} height={20} color={colors.grey01} ml={6}>{!!client.subscription ? client.subscription.renovationDate : "--"}</Text>
                                        </Block>
                                        <Block width="100%" height={8} color={colors.grey04} br={4}>
                                            <Block width={!!client.subscription ? ((100 - (client.subscription.remaining * 100 / client.subscription.duration)) + "%") : "0%"} height={8} color={colors.yellow01} br={4} />
                                        </Block>
                                    </Block>
                                </Portlet>
                            </Block>
                            <Block ml={10} mr={10} width="calc(50% - 20px)">
                                <Portlet
                                    width="100%"
                                    height="420px"
                                    actions={
                                        <Block row>
                                            <Block mr={12}>
                                                <Button color={colors.grey01} loading={loadingUserUpdateAccount} onClick={() => formEditAccountRef.current.handleSubmit()}>Edit</Button>
                                            </Block>
                                        </Block>
                                    }
                                >
                                    <Form
                                        innerRef={formEditAccountRef}
                                        initialValues={{
                                            completeName: client.completeName,
                                            company: client.company,
                                            email: client.email
                                        }}
                                        schema={yup.object().shape({
                                            completeName: yup.string().required(),
                                            company: yup.string().required(),
                                            email: yup.string().email().required(),
                                            password: yup.string()
                                        })}
                                        onSubmit={(values) => mutateUserUpdateAccount({ variables: { id: clientId, ...values } })}
                                    >
                                        <>
                                            <FormGroup title="Account" description="General data">
                                                <Block width={356}><Input Component={TextInput} name="completeName" label="Name" /></Block>
                                                <Block width={256}><Input Component={TextInput} name="company" label="Company" /></Block>
                                            </FormGroup>
                                            <FormGroup description="Access information">
                                                <Block width={356}><Input Component={TextInput} name="email" label="Email" /></Block>
                                                <Block width={256}><Input Component={TextInput} name="password" label="Password" type="password" /></Block>
                                            </FormGroup>
                                        </>
                                    </Form>
                                </Portlet>
                            </Block>
                            <Block ml={10} mr={10} width="calc(50% - 20px)">
                                <Portlet
                                    width="100%"
                                    height="420px"
                                    actions={
                                        <Block row>
                                            <Block>
                                                <Button color={colors.grey01} loading={loadingUserUpdateBilling} onClick={() => formEditBillingRef.current.handleSubmit()}>Edit</Button>
                                            </Block>
                                        </Block>
                                    }
                                >
                                    <Form
                                        innerRef={formEditBillingRef}
                                        initialValues={{
                                            name: client.name,
                                            address: client.address,
                                            city: client.city,
                                            postalCode: client.postalCode,
                                            nif: client.nif
                                        }}
                                        schema={yup.object().shape({
                                            name: yup.string().required(),
                                            address: yup.string().required(),
                                            city: yup.string().required(),
                                            postalCode: yup.string().required(),
                                            nif: yup.string().required()
                                        })}
                                        onSubmit={(values) => mutateUserUpdateBilling({ variables: { id: clientId, ...values } })}
                                    >
                                        <>
                                            <FormGroup title="Billing information" description="Billing information">
                                                <Block width={356}><Input Component={TextInput} name="name" label="Name" /></Block>
                                                <Input Component={TextInput} name="address" label="Address" />
                                                <Block row>
                                                    <Block width={156} mr={10}>
                                                        <Input Component={TextInput} name="city" label="City" />
                                                    </Block>
                                                    <Block width={156} ml={10}>
                                                        <Input Component={TextInput} name="postalCode" label="Postal Code" />
                                                    </Block>
                                                </Block>
                                                <Block width={256}><Input Component={TextInput} name="nif" label="NIF or VAT" /></Block>
                                            </FormGroup>
                                        </>
                                    </Form>
                                </Portlet>
                            </Block>
                        </Block>
                        <Block mt={44}>
                            <Block row center>
                                <Block>
                                    <Text size={16} height={24} color={colors.grey01}>Client receipts</Text>
                                </Block>
                            </Block>
                            <Block mt={30}>
                                <AdvancedTable
                                    hasMore={receipts.pageInfo.hasNextPage}
                                    next={() => requestReceipts({ next: true })}
                                    emptyLabel="No receipts"
                                    columns={[
                                        {
                                            label: "Document id",
                                            value: "receiptId",
                                        },
                                        {
                                            label: "Date",
                                            value: "receiptDate"
                                        },
                                        {
                                            label: "",
                                            value: "actions"
                                        }
                                    ]}
                                    rows={receipts.edges.map((edge) => {
                                        return ({
                                            receiptId: edge.node.receiptId,
                                            receiptDate: edge.node.receiptDate,
                                            actions: <Block row right mr={-16} pointer>
                                                <Block row height={52} pl={16} pr={16} center style={{ borderLeft: `1px solid ${colors.grey05}` }} onClick={() => window.open(edge.node.receiptUrl, '_blank')}>
                                                    <Icon name="file_text_16px" />
                                                </Block>
                                            </Block>
                                        });
                                    })}
                                />
                            </Block>
                        </Block>
                    </>}
                </Block>
            </SlidingPane>
            <ModalSubscription
                clientId={clientId}
                isOpen={isManageSubscriptionModalOpen}
                onRequestClose={() => setIsManageSubscriptionModalOpen(false)}
                onUpdate={() => queryClient({ variables: { id: clientId } })}
            />
        </>
    );
}

const ButtonBack = styled(Block)`
    position: fixed;
    top: 24px;
    left: 22px;
    width: 52px;
    height: 52px;
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(102, 102, 102, 0.16);
    border-radius: 26px;
    z-index: 99;
    cursor: pointer;
`;