import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import gql from 'graphql-tag';
import React, { useEffect, useRef, useState } from "react";
import * as yup from 'yup';
import { Block, Form, FormGroup, Icon, Input, Loading, ModalThatSlides, Portlet, Text, TextInput } from '../../../../components';
import colors from "../../../../utils/colors";

export default function ModalUpdate({ userId, isOpen, onRequestClose, onUpdate }) {
    const formRef = useRef(null);
    const [user, setUser] = useState(null);

    const [queryUser, { loading: loadingUser }] = useLazyQuery(
        gql`
            query user($id: ID!) {
                user(id: $id) {
                    id
                    name
                    email
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.user) {
                    setUser(data.user);
                }
            }
        }
    );

    const [mutateUserUpdate, { loading: loadingUserUpdate }] = useMutation(
        gql`
            mutation userUpdate(
                $id: ID!,
                $name: String!
                $email: String!
                $password: String
            ) {
                userUpdate(
                    id: $id
                    name: $name
                    email: $email
                    password: $password
                ) {
                    id
                    name
                    email
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.userUpdate) {
                    !!onUpdate && onUpdate(data.userUpdate);
                    !!onRequestClose && onRequestClose();
                }
            }
        }
    );

    useEffect(() => {
        if (!isOpen) {
            setUser(null);
        }
    }, [isOpen]);

    useEffect(() => {
        if (!!userId) {
            queryUser({ variables: { id: userId } });
        }
    }, [userId]);

    return (
        <ModalThatSlides
            id="user-update"
            title="User update"
            width="820px"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            actions={
                <Block row>
                    <Block
                        row
                        center
                        pl={16}
                        width={176}
                        height={52}
                        color={colors.grey02}
                        pointer={!loadingUserUpdate}
                        onClick={() => {
                            formRef.current.handleSubmit();
                        }}
                    >
                        {loadingUserUpdate ? <Loading /> : <Text size={14} height={28} color={colors.white01}>Save</Text>}
                    </Block>
                    <Block row center middle width={52} height={52} color={colors.grey01} pointer onClick={onRequestClose}>
                        <Icon name="close_16px" color={colors.white01} />
                    </Block>
                </Block>
            }
        >
            {(loadingUser) && <Block width="100%" height="100%" center middle><Loading color={colors.grey01} /></Block>}
            {!!user && <Form
                innerRef={formRef}
                initialValues={user}
                schema={yup.object().shape({
                    name: yup.string().required(),
                    email: yup.string().email().required(),
                    password: yup.string()
                })}
                onSubmit={(values) => {
                    if (!loadingUserUpdate) {
                        mutateUserUpdate({ variables: values });
                    }
                }}
            >
                {({
                    errors,
                    handleSubmit,
                }) => {
                    return (
                        <form onSubmit={handleSubmit} autoComplete="off">
                            <Portlet width="100%">
                                <FormGroup>
                                    <Block>
                                        <Input Component={TextInput} error={errors.name} name="name" label="Name" />
                                    </Block>
                                    <Block>
                                        <Input Component={TextInput} error={errors.email} name="email" label="Email" />
                                    </Block>
                                    <Block>
                                        <Input type="password" Component={TextInput} error={errors.password} name="password" label="Password" />
                                    </Block>
                                </FormGroup>
                            </Portlet>
                        </form>
                    )
                }}
            </Form>}
        </ModalThatSlides>
    );
}