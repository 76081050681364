import React, { useState } from "react";
import styled from "styled-components";
import colors from "../../utils/colors";
import Block from "../Block";
import Icon from "../Icon";
import Text from "../Text";

export default function PortletOpenClose(
    {
        title,
        children
    }
) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Wrapper>
            <Header row center space="between" pointer onClick={() => setIsOpen(!isOpen)}>
                <Text size={24} height={32}>{title}</Text>
                <Block>
                    {isOpen ? <Icon name="down_16px" /> : <Icon name="up_16px" />}
                </Block>
            </Header>
            <Content isOpen={isOpen}>
                {children}
            </Content>
        </Wrapper>
    );
}

const Wrapper = styled(Block)`
    margin-bottom: 24px;

    :last-of-type {
        margin-bottom: 0;
    }
`;

const Header = styled(Block)`
    border-bottom: 1px solid ${colors.grey03};
    padding-bottom: 12px;
`;

const Content = styled(Block)`
    padding-top: 24px;
    display: ${({ isOpen }) => !!isOpen ? "block" : "none"} !important;
    visibility: ${({ isOpen }) => !!isOpen ? "visible" : "hidden"};
`;