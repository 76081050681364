import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { Dashboard, EmptyWithTitle } from "./layouts";
import GlobalStyle from './styles/global';
import './styles/icons.css';
import './utils/yupMessages';

function App() {
  return (
    <>
      <GlobalStyle />
      <Router>
        <Switch>
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/" component={EmptyWithTitle} />
          <Redirect exact from="/" to="dashboard" />
        </Switch>
      </Router>
    </>
  );
}

export default App;
