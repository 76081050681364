import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Block, Icon, Text } from '../../../components';
import colors from '../../../utils/colors';

function MenuItem({ to = "/", text, icon }) {
    return (
        <Link to={to} activeClassName="selected">
            <Block row center pl={40}>
                {!!icon && <div className="sidebar-menu-icon"><Icon name={icon} /></div>}
                {!!text && <Text size={14} height={32} ml={!!Icon ? 14 : 0}>{text}</Text>}
            </Block>
        </Link>
    );
}

const Link = styled(NavLink)`
    text-decoration: none;
    margin-bottom: 12px;

    :last-of-type {
        margin-bottom: 0;
    }
    
    span {
        color: ${colors.grey03} !important;
    }

    .sidebar-menu-icon span::before {
        color: ${colors.grey03} !important;
    }

    &.selected {
        span {
            color: ${colors.white01} !important;
        }

        .sidebar-menu-icon span::before {
            color: ${colors.yellow01} !important;
        }
    }
`;

export default MenuItem;