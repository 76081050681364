import React from "react";
import ReactLoading from 'react-loading';
import colors from "../../utils/colors";

function Loading({ color = colors.white01 }) {
    return (
        <ReactLoading type="spin" color={color} height={20} width={20} />
    );
}

export default React.memo(Loading);