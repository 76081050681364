import React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import colors from '../../utils/colors';
import Block from '../Block';
import Icon from '../Icon';
import Text from '../Text';

ReactModal.setAppElement("body");

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        padding: 0,
        border: "none",
        borderRadius: 16
    },
    overlay: {
        backgroundColor: "rgba(34, 37, 39, 0.8)"
    }
};

export default function Modal({ title, isOpen, onRequestClose, children, ...props }) {
    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={customStyles}
            {...props}
        >
            <Wrapper width={480}>
                <ButtonClose center middle pointer onClick={onRequestClose}>
                    <Icon color={colors.white01} name="close_16px" />
                </ButtonClose>
                {!!title && <Title>
                    <Text size={16} height={24} color={colors.grey01}>{title}</Text>
                </Title>}
                <Block pt={!!title ? 100 : 60} pr={24} pb={24} pl={24}>
                    {children}
                </Block>
            </Wrapper>
        </ReactModal>
    );
}

const Wrapper = styled(Block)`
    position: relative;
    ::before {
        color: ${colors.grey60};
    }
`;

const Title = styled.div`
    position: absolute;
    top: 30px;
    left: 24px;
`;

const ButtonClose = styled(Block)`
    position: absolute;
    top: 18px;
    right: 24px;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background-color: ${colors.grey01};
`;