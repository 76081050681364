import React from 'react';
import { Redirect, useHistory } from 'react-router';
import { Route, Switch } from "react-router-dom";
import styled from 'styled-components';
import { Block } from '../../components';
import { Login } from '../../pages';
import { SvgLogoBlack } from '../../svgs';

function EmptyWithNavigationBack({ }) {
    const history = useHistory();


    return (
        <Container width="100%" height="100%" style={{ overflow: "auto", minWidth: "1366px" }}>
            <Logo center>
                <SvgLogoBlack />
            </Logo>
            <Block width="100%" height="100%">
                <Switch>
                    <Route exact path="/login" component={Login} />

                    <Route
                        exact
                        path="/"
                        render={() => <Redirect to="/login" />}
                    />
                </Switch>
            </Block>
        </Container>
    );
}

const Container = styled(Block)`
    position: relative;
`;

const Logo = styled(Block)`
    position: absolute;
    top: 34px;
    left: 0;
    right: 0;
    z-index: 99;
`;

export default EmptyWithNavigationBack;