import React from "react";
import styled from "styled-components";
import colors from "../../utils/colors";
import Block from "../Block";
import Text from "../Text";

export default function FormGroup(
    {
        title,
        description,
        children
    }
) {
    return (
        <Wrapper>
            {(!!title || !!description) && <Block>
                {!!title && <Block mb={20}>
                    <Text size={16} height={24} color={colors.grey01}>{title}</Text>
                </Block>}
                {!!description && <Block mb={8}>
                    <Text size={12} height={20} color={colors.grey03}>{description}</Text>
                </Block>}
            </Block>}
            <Block>
                {!!children && !Array.isArray(children) ? children : children.map((child, index) => {
                    return (<Block key={index} mb={children.length - 1 === index ? 0 : 12}>{child}</Block>);
                })}
            </Block>
        </Wrapper>
    );
}


const Wrapper = styled(Block)`
    margin-bottom: 20px;
    border-bottom: 1px solid ${colors.grey05};
    padding-bottom: 20px;

    :last-of-type {
        margin-bottom: 0;
        border-bottom: 0;
        padding-bottom: 0;
    }
`;