import React from 'react';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import styled from 'styled-components';
import colors from '../../utils/colors';
import Block from '../Block';
import Text from '../Text';

export default function ModalThatSlides({ id, title, onRequestClose, actions, children, ...props }) {
    return (
        <SlidingPane
            className="sliding-modal"
            overlayClassName="sliding-modal-overlay"
            hideHeader={true}
            onRequestClose={onRequestClose}
            {...props}
        >
            <Block height="100%" color={colors.grey05}>
                <Block height={52} width="100%" center row color={colors.white01} pl={24} space="between">
                    <Block row center>
                        <Text size={18} height={32} color={colors.grey01}>{title}</Text>
                    </Block>
                    <Block>
                        {actions}
                    </Block>
                </Block>
                <Content pt={72} pr={120} pb={72} pl={120} id={id}>
                    {children}
                </Content>
            </Block>
        </SlidingPane>
    );
}

const Content = styled(Block)`
    overflow-y: auto;
`;