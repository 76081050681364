import React from "react";
import styled, { css } from "styled-components";
import colors from "../../utils/colors";

export default function Icon(
    {
        name,
        size,
        color = colors.grey01
    }
) {
    return (
        <Element className={`icon-${name}`} color={color} size={size} />
    );
}

const Element = styled.span`
    ::before {
        ${({ size }) => css`font-size: ${size}px`};
        color: ${({ color }) => color};
    }
`;