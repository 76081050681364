import React from 'react';
import styled from 'styled-components';
import colors from '../../utils/colors';
import Block from '../Block';
import Button from '../Button';
import Icon from '../Icon';
import Text from '../Text';

function Stepper({ currentIndex = 2, screens = [], onClickNext, onClickPrevious }) {
    return (
        <Wrapper>
            <Screens>
                {screens.map((item, index) => (
                    <Screen key={index} show={index <= currentIndex}>
                        {item.screen}
                    </Screen>
                ))}
            </Screens>
            <Bottom>
                <Bar>
                    <StepBar length={screens.length} index={currentIndex} />
                    {screens.map((item, index) => (
                        <div key={index}>
                            <Title
                                length={screens.length}
                                index={index}>
                                <Text
                                    color={currentIndex === index ? colors.grey01 : colors.grey03}
                                    size={14}
                                    height={24}
                                >
                                    {item.title}
                                </Text>
                            </Title>
                            <Ball
                                selected={currentIndex >= index}
                                length={screens.length}
                                index={index}
                            />
                        </div>
                    ))}
                </Bar>
                <Right>
                    {(currentIndex > 0 && currentIndex < screens.length - 1) && <Block pointer center middle width={52} height={52} br={26} color={colors.white01} style={{ marginRight: 8, boxShadow: "0px 4px 16px rgba(102, 102, 102, 0.16)" }} onClick={onClickPrevious}>
                        <Icon name="arrow_left_20px" color={colors.grey01} />
                    </Block>}
                    {currentIndex < screens.length - 1 && <Button width={148} color={colors.grey01} onClick={onClickNext}>
                        <Text
                            color={colors.white01}
                            size={16}
                            height={24}
                            mr={12}
                        >
                            Next step
                        </Text>
                        <Icon name="arrow_right_20px" color={colors.white01} />
                    </Button>}
                </Right>
            </Bottom>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    overflow: hidden;
`;

const Screens = styled.div`
    display: flex;
    flex-flow: row;
    height: 100%;
    width: 100%;
    overflow: hidden;
`;

const Screen = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    right: 0;

    height: 100%;
    min-width: 100%;

    transform: ${({ show }) => show ? `translateX(-100%)` : `translateX(0%)`};
    transition: transform 0.5s linear;
`;

const Bottom = styled.div`
    position: absolute;
    bottom: 28px;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    align-items: flex-end;;
    justify-content: center;
`;

const Right = styled.div`
    position: absolute;
    bottom: 0;
    right: 22px;
    display: flex;
    flex-flow: row;
`;

const Bar = styled.div`
    position: relative;
    height: 4px;
    width: 570px;
    border-radius: 4px;
    background-color: ${colors.grey04};
`;

const StepBar = styled.div`
    height: 4px;
    width: ${({ length, index }) => `${(index * (570 / (length - 1)))}px`};
    border-radius: 4px;
    background-color: ${colors.yellow01};
    position: absolute;
    bottom: 0px;
    left: 0;

    transition: width 0.5s linear;
`;

const Title = styled.div`
    position: absolute;
    bottom: 8px;
    left: ${({ length, index }) => {
        if (index === 0) { return `0px` }
        if (index === length - 1) { return `calc(570px + 4px)` }

        return `${(index * (570 / (length - 1)))}px`;
    }};

    transform: ${({ length, index }) => {
        if (index === 0) { return `translateX(0%)` }
        if (index === length - 1) { return `translateX(-100%)` }
        return `translateX(-50%)`;
    }};
`;

const Ball = styled.div`
    height: 4px;
    width: 4px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: ${({ length, index }) => {
        if (index === 0) { return `0px` }
        if (index === length - 1) { return `570px` }

        return `${(index * (570 / (length - 1))) - 2}px`;
    }};
    border-radius: 2px;
    background-color: ${({ selected }) => selected ? colors.grey01 : colors.grey03};
`;

export default Stepper;