import React, { useContext } from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import styled from 'styled-components';
import { Block, Text } from '../../components';
import { Packs, Pages, Users } from '../../pages';
import Clients from '../../pages/Clients';
import checkAuth from '../../utils/checkAuth';
import colors from '../../utils/colors';
import { store } from '../../utils/store';
import MenuItem from './MenuItem';

function Dashboard() {
    const { user } = useContext(store);

    return (
        <Block row width="100%" height="100%" style={{ overflow: "hidden", minWidth: "1366px" }}>
            <Block color={colors.grey01} width={220} height="100%" space="between">
                <Block>
                    <Block width="100%" pt={42} pb={70} center>
                        <Logo src={process.env.PUBLIC_URL + '/img/logo_white.png'} />
                    </Block>
                    <Block width="100%">
                        <MenuItem to="/dashboard/pages" text="Pages" icon="dashboard_16px" />
                        <MenuItem to="/dashboard/packs" text="Packs" icon="dashboard_16px" />
                        <MenuItem to="/dashboard/users" text="Users" icon="dashboard_16px" />
                        <MenuItem to="/dashboard/clients" text="Clients" icon="dashboard_16px" />
                    </Block>
                </Block>
                <Block>
                    <Block pt={24} pl={18} pb={24}>
                        <Text size={18} height={32} color={colors.white01}>{user.name}</Text>
                    </Block>
                </Block>
            </Block>
            <Block width="calc(100% - 220px)" height="100%" color={colors.grey05} style={{ overflow: "hidden", position: "relative" }}>
                <Switch>
                    <Route exact path="/dashboard/pages" component={Pages} />
                    <Route exact path="/dashboard/packs" component={Packs} />
                    <Route exact path="/dashboard/users" component={Users} />
                    <Route exact path="/dashboard/clients" component={Clients} />
                    <Route
                        exact
                        path="/dashboard"
                        render={() => <Redirect to="/dashboard/pages" />}
                    />
                </Switch>
            </Block>
        </Block>
    );
}

const Logo = styled.img`
    width: 123px;
    height: 14px;
`;

export default checkAuth(Dashboard);