import React from "react";
import styled, { css } from 'styled-components';
import colors from "../../../utils/colors";
import Block from '../../Block';
import Icon from "../../Icon";
import Text from '../../Text';

function CheckInput(
    {
        label,
        onChangeValue,
        value = false
    }
) {
    return (
        <Block row center pointer width="100%" onClick={() => !!onChangeValue && onChangeValue(!value)} space="between">
            <Block row center>
                <Square selected={value} center middle>
                    {!!value && <Icon name="Check" color={colors.white01} />}
                </Square>
                {!!label && <Text noselect size={14} height={24} ml={8} color={colors.grey01}>{label}</Text>}
            </Block>
        </Block>
    );
}

export default React.memo(CheckInput);

const Square = styled(Block)`
    min-width: 20px;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    background-color: ${colors.grey06};

    ${({ selected }) => css`
        border: 1px solid ${selected ? colors.grey03 : colors.grey06};
    `}
`;