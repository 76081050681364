import React from "react";
import styled from "styled-components";
import colors from "../../utils/colors";
import Block from "../Block";

export default function Portlet(
    {
        width = "480px",
        height = "fit-content",
        children,
        actions
    }
) {
    return (
        <Wrapper width={width} color={colors.white01}>
            <Content height={height}>
                {children}
            </Content>
            {!!actions && <>
                <Block height={1} color={colors.grey05} />
                <Actions>
                    {actions}
                </Actions>
            </>}
        </Wrapper>
    );
}

const Wrapper = styled(Block)`
    width: ${({ width }) => width};
    border-radius: 16px;
    margin-bottom: 12px;

    :last-of-type {
        margin-bottom: 0;
    }
`;

const Content = styled(Block)`
    height: ${({ height }) => height};
    padding: 24px;
`;

const Actions = styled(Block)`
    padding: 24px;
`;