import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import gql from 'graphql-tag';
import React, { useEffect, useRef, useState } from "react";
import * as yup from 'yup';
import { Block, Form, FormGroup, HTMLInput, Icon, Input, Loading, ModalThatSlides, Portlet, PortletOpenClose, Text, TextAreaInput, TextInput } from '../../../../components';
import colors from "../../../../utils/colors";

export default function ModalUpdate({ pageId, isOpen, onRequestClose, onUpdate }) {
    const formRef = useRef(null);
    const [page, setPage] = useState(null);

    const [queryPage, { loading: loadingPage }] = useLazyQuery(
        gql`
            query page($id: ID!) {
                page(id: $id) {
                    id
                    title
                    slug
                    content
                    metatags
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.page) {
                    setPage(data.page);
                }
            }
        }
    );

    const [mutatePageUpdate, { loading: loadingPageUpdate }] = useMutation(
        gql`
            mutation pageUpdate(
                $id: ID!,
                $title: String!
                $slug: String!
                $content: Json!
                $metatags: Json!
            ) {
                pageUpdate(
                    id: $id
                    title: $title
                    slug: $slug
                    content: $content
                    metatags: $metatags
                ) {
                    id
                    title
                    slug
                    content
                    metatags
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.pageUpdate) {
                    !!onUpdate && onUpdate(data.pageUpdate);
                    !!onRequestClose && onRequestClose();
                }
            }
        }
    );

    useEffect(() => {
        if (!isOpen) {
            setPage(null);
        }
    }, [isOpen]);

    useEffect(() => {
        if (!!pageId) {
            queryPage({ variables: { id: pageId } });
        }
    }, [pageId]);

    const getContentStructure = (errors) => {
        switch (pageId) {
            case "1": {
                return <>
                    <PortletOpenClose title="Header">
                        <FormGroup>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.header) && errors.content.header.aboutLabel} name="content.header.aboutLabel" label="About label" />
                            </Block>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.header) && errors.content.header.howItsDoneLabel} name="content.header.howItsDoneLabel" label="How it's done label" />
                            </Block>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.header) && errors.content.header.whatItIs} name="content.header.whatItIs" label="What it is label" />
                            </Block>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.header) && errors.content.header.platform} name="content.header.platform" label="Platform label" />
                            </Block>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.header) && errors.content.header.pricing} name="content.header.pricing" label="Pricing label" />
                            </Block>
                        </FormGroup>
                    </PortletOpenClose>
                    <PortletOpenClose title="Banner">
                        <FormGroup>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.banner) && errors.content.banner.smallTitle} name="content.banner.smallTitle" label="Small title" />
                            </Block>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.banner) && errors.content.banner.title} name="content.banner.title" label="Title" />
                            </Block>
                            <Block>
                                <Input Component={TextAreaInput} error={(errors.content && errors.content.banner) && errors.content.banner.description} name="content.banner.description" label="Description" />
                            </Block>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.banner) && errors.content.banner.buttonLabel} name="content.banner.buttonLabel" label="Button label" />
                            </Block>
                        </FormGroup>
                    </PortletOpenClose>
                    <PortletOpenClose title="Start Using Now">
                        <FormGroup>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.startUsingNow) && errors.content.startUsingNow.title} name="content.startUsingNow.title" label="Title" />
                            </Block>
                            <Block>
                                <Input Component={TextAreaInput} error={(errors.content && errors.content.startUsingNow) && errors.content.startUsingNow.description} name="content.startUsingNow.description" label="Description" />
                            </Block>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.startUsingNow) && errors.content.startUsingNow.buttonLabel} name="content.startUsingNow.buttonLabel" label="Button label" />
                            </Block>
                        </FormGroup>
                    </PortletOpenClose>
                    <PortletOpenClose title="About">
                        <FormGroup>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.about) && errors.content.about.smallTitle} name="content.about.smallTitle" label="Small title" />
                            </Block>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.about) && errors.content.about.title} name="content.about.title" label="Title" />
                            </Block>
                            <Block>
                                <Input Component={TextAreaInput} error={(errors.content && errors.content.about) && errors.content.about.description} name="content.about.description" label="Description" />
                            </Block>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.about) && errors.content.about.buttonlabel} name="content.about.buttonlabel" label="Button label" />
                            </Block>
                        </FormGroup>
                        <FormGroup>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.about) && errors.content.about.item1Title} name="content.about.item1Title" label="Item 1 Title" />
                            </Block>
                            <Block>
                                <Input Component={TextAreaInput} error={(errors.content && errors.content.about) && errors.content.about.item1Description} name="content.about.item1Description" label="Item 1 Description" />
                            </Block>
                        </FormGroup>
                        <FormGroup>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.about) && errors.content.about.item2Title} name="content.about.item2Title" label="Item 2 Title" />
                            </Block>
                            <Block>
                                <Input Component={TextAreaInput} error={(errors.content && errors.content.about) && errors.content.about.item2Description} name="content.about.item2Description" label="Item 2 Description" />
                            </Block>
                        </FormGroup>
                        <FormGroup>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.about) && errors.content.about.item3Title} name="content.about.item3Title" label="Item 3 Title" />
                            </Block>
                            <Block>
                                <Input Component={TextAreaInput} error={(errors.content && errors.content.about) && errors.content.about.item3Description} name="content.about.item3Description" label="Item 3 Description" />
                            </Block>
                        </FormGroup>
                    </PortletOpenClose>
                    <PortletOpenClose title="How it's done">
                        <FormGroup>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.howItsDone) && errors.content.howItsDone.smallTitle} name="content.howItsDone.smallTitle" label="Small title" />
                            </Block>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.howItsDone) && errors.content.howItsDone.title} name="content.howItsDone.title" label="Title" />
                            </Block>
                            <Block>
                                <Input Component={TextAreaInput} error={(errors.content && errors.content.howItsDone) && errors.content.howItsDone.description} name="content.howItsDone.description" label="Description" />
                            </Block>
                        </FormGroup>
                        <FormGroup>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.howItsDone) && errors.content.howItsDone.item1Title} name="content.howItsDone.item1Title" label="Item 1 Title" />
                            </Block>
                            <Block>
                                <Input Component={TextAreaInput} error={(errors.content && errors.content.howItsDone) && errors.content.howItsDone.item1Description} name="content.howItsDone.item1Description" label="Item 1 Description" />
                            </Block>
                        </FormGroup>
                        <FormGroup>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.howItsDone) && errors.content.howItsDone.item2Title} name="content.howItsDone.item2Title" label="Item 2 Title" />
                            </Block>
                            <Block>
                                <Input Component={TextAreaInput} error={(errors.content && errors.content.howItsDone) && errors.content.howItsDone.item2Description} name="content.howItsDone.item2Description" label="Item 2 Description" />
                            </Block>
                        </FormGroup>
                        <FormGroup>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.howItsDone) && errors.content.howItsDone.item3Title} name="content.howItsDone.item3Title" label="Item 3 Title" />
                            </Block>
                            <Block>
                                <Input Component={TextAreaInput} error={(errors.content && errors.content.howItsDone) && errors.content.howItsDone.item3Description} name="content.howItsDone.item3Description" label="Item 3 Description" />
                            </Block>
                        </FormGroup>
                        <FormGroup>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.howItsDone) && errors.content.howItsDone.item4Title} name="content.howItsDone.item4Title" label="Item 4 Title" />
                            </Block>
                            <Block>
                                <Input Component={TextAreaInput} error={(errors.content && errors.content.howItsDone) && errors.content.howItsDone.item4Description} name="content.howItsDone.item4Description" label="Item 4 Description" />
                            </Block>
                        </FormGroup>
                        <FormGroup>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.howItsDone) && errors.content.howItsDone.item5Title} name="content.howItsDone.item5Title" label="Item 5 Title" />
                            </Block>
                            <Block>
                                <Input Component={TextAreaInput} error={(errors.content && errors.content.howItsDone) && errors.content.howItsDone.item5Description} name="content.howItsDone.item5Description" label="Item 5 Description" />
                            </Block>
                        </FormGroup>
                    </PortletOpenClose>
                    <PortletOpenClose title="Are you ready">
                        <FormGroup>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.areYouReady) && errors.content.areYouReady.title} name="content.areYouReady.title" label="Title" />
                            </Block>
                            <Block>
                                <Input Component={TextAreaInput} error={(errors.content && errors.content.areYouReady) && errors.content.areYouReady.description} name="content.areYouReady.description" label="Description" />
                            </Block>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.areYouReady) && errors.content.areYouReady.buttonLabel} name="content.areYouReady.buttonLabel" label="Button label" />
                            </Block>
                        </FormGroup>
                    </PortletOpenClose>
                    <PortletOpenClose title="What It Is">
                        <FormGroup>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.whatsItIs) && errors.content.whatsItIs.smallTitle} name="content.whatsItIs.smallTitle" label="Small title" />
                            </Block>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.whatsItIs) && errors.content.whatsItIs.title} name="content.whatsItIs.title" label="Title" />
                            </Block>
                            <Block>
                                <Input Component={TextAreaInput} error={(errors.content && errors.content.whatsItIs) && errors.content.whatsItIs.description} name="content.whatsItIs.description" label="Description" />
                            </Block>
                        </FormGroup>
                        <FormGroup>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.whatsItIs) && errors.content.whatsItIs.item1Title} name="content.whatsItIs.item1Title" label="Item 1 Title" />
                            </Block>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.whatsItIs) && errors.content.whatsItIs.item1SubTitle} name="content.whatsItIs.item1SubTitle" label="Item 1 SubTitle" />
                            </Block>
                            <Block>
                                <Input Component={TextAreaInput} error={(errors.content && errors.content.whatsItIs) && errors.content.whatsItIs.item1Description} name="content.whatsItIs.item1Description" label="Item 1 Description" />
                            </Block>
                        </FormGroup>
                        <FormGroup>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.whatsItIs) && errors.content.whatsItIs.item2Title} name="content.whatsItIs.item2Title" label="Item 2 Title" />
                            </Block>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.whatsItIs) && errors.content.whatsItIs.item2SubTitle} name="content.whatsItIs.item2SubTitle" label="Item 2 SubTitle" />
                            </Block>
                            <Block>
                                <Input Component={TextAreaInput} error={(errors.content && errors.content.whatsItIs) && errors.content.whatsItIs.item2Description} name="content.whatsItIs.item2Description" label="Item 2 Description" />
                            </Block>
                        </FormGroup>
                        <FormGroup>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.whatsItIs) && errors.content.whatsItIs.item3Title} name="content.whatsItIs.item3Title" label="Item 3 Title" />
                            </Block>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.whatsItIs) && errors.content.whatsItIs.item3SubTitle} name="content.whatsItIs.item3SubTitle" label="Item 3 SubTitle" />
                            </Block>
                            <Block>
                                <Input Component={TextAreaInput} error={(errors.content && errors.content.whatsItIs) && errors.content.whatsItIs.item3Description} name="content.whatsItIs.item3Description" label="Item 3 Description" />
                            </Block>
                        </FormGroup>
                        <FormGroup>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.whatsItIs) && errors.content.whatsItIs.item4Title} name="content.whatsItIs.item4Title" label="Item 4 Title" />
                            </Block>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.whatsItIs) && errors.content.whatsItIs.item4SubTitle} name="content.whatsItIs.item4SubTitle" label="Item 4 SubTitle" />
                            </Block>
                            <Block>
                                <Input Component={TextAreaInput} error={(errors.content && errors.content.whatsItIs) && errors.content.whatsItIs.item4Description} name="content.whatsItIs.item4Description" label="Item 4 Description" />
                            </Block>
                        </FormGroup>
                        <FormGroup>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.whatsItIs) && errors.content.whatsItIs.item5Title} name="content.whatsItIs.item5Title" label="Item 5 Title" />
                            </Block>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.whatsItIs) && errors.content.whatsItIs.item5SubTitle} name="content.whatsItIs.item5SubTitle" label="Item 5 SubTitle" />
                            </Block>
                            <Block>
                                <Input Component={TextAreaInput} error={(errors.content && errors.content.whatsItIs) && errors.content.whatsItIs.item5Description} name="content.whatsItIs.item5Description" label="Item 5 Description" />
                            </Block>
                        </FormGroup>
                    </PortletOpenClose>
                    <PortletOpenClose title="Platform">
                        <FormGroup>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.platform) && errors.content.platform.smallTitle} name="content.platform.smallTitle" label="Small title" />
                            </Block>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.platform) && errors.content.platform.title} name="content.platform.title" label="Title" />
                            </Block>
                            <Block>
                                <Input Component={TextAreaInput} error={(errors.content && errors.content.platform) && errors.content.platform.description} name="content.platform.description" label="Description" />
                            </Block>
                        </FormGroup>
                        <FormGroup>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.platform) && errors.content.platform.item1Title} name="content.platform.item1Title" label="Item 1 Title" />
                            </Block>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.platform) && errors.content.platform.item1SubTitle} name="content.platform.item1SubTitle" label="Item 1 SubTitle" />
                            </Block>
                            <Block>
                                <Input Component={TextAreaInput} error={(errors.content && errors.content.platform) && errors.content.platform.item1Description} name="content.platform.item1Description" label="Item 1 Description" />
                            </Block>
                        </FormGroup>
                        <FormGroup>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.platform) && errors.content.platform.item2Title} name="content.platform.item2Title" label="Item 2 Title" />
                            </Block>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.platform) && errors.content.platform.item2SubTitle} name="content.platform.item2SubTitle" label="Item 2 SubTitle" />
                            </Block>
                            <Block>
                                <Input Component={TextAreaInput} error={(errors.content && errors.content.platform) && errors.content.platform.item2Description} name="content.platform.item2Description" label="Item 2 Description" />
                            </Block>
                        </FormGroup>
                    </PortletOpenClose>
                    <PortletOpenClose title="Pricing">
                        <FormGroup>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.pricing) && errors.content.pricing.smallTitle} name="content.pricing.smallTitle" label="Small title" />
                            </Block>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.pricing) && errors.content.pricing.title} name="content.pricing.title" label="Title" />
                            </Block>
                            <Block>
                                <Input Component={TextAreaInput} error={(errors.content && errors.content.pricing) && errors.content.pricing.description} name="content.pricing.description" label="Description" />
                            </Block>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.pricing) && errors.content.pricing.email} name="content.pricing.email" label="Email" />
                            </Block>
                        </FormGroup>
                    </PortletOpenClose>
                </>
            }
            case "2": {
                return <>
                    <PortletOpenClose title="Banner">
                        <FormGroup>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.banner) && errors.content.banner.smallTitle} name="content.banner.smallTitle" label="Small title" />
                            </Block>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.banner) && errors.content.banner.title} name="content.banner.title" label="Title" />
                            </Block>
                        </FormGroup>
                    </PortletOpenClose>
                    <PortletOpenClose title="Terms">
                        <FormGroup>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.terms) && errors.content.terms.title} name="content.terms.title" label="Title" />
                            </Block>
                            <Block>
                                <Input Component={HTMLInput} error={(errors.content && errors.content.terms) && errors.content.terms.text} name="content.terms.text" label="Text" />
                            </Block>
                        </FormGroup>
                    </PortletOpenClose>
                    <PortletOpenClose title="Policy">
                        <FormGroup>
                            <Block>
                                <Input Component={TextInput} error={(errors.content && errors.content.policy) && errors.content.policy.title} name="content.policy.title" label="Title" />
                            </Block>
                            <Block>
                                <Input Component={HTMLInput} error={(errors.content && errors.content.policy) && errors.content.policy.text} name="content.policy.text" label="Text" />
                            </Block>
                        </FormGroup>
                    </PortletOpenClose>
                </>
            }
        }
    }

    return (
        <ModalThatSlides
            id="page-update"
            title="Page update"
            width="820px"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            actions={
                <Block row>
                    <Block
                        row
                        center
                        pl={16}
                        width={176}
                        height={52}
                        color={colors.grey02}
                        pointer={!loadingPageUpdate}
                        onClick={() => {
                            formRef.current.handleSubmit();
                        }}
                    >
                        {loadingPageUpdate ? <Loading /> : <Text size={14} height={28} color={colors.white01}>Save</Text>}
                    </Block>
                    <Block row center middle width={52} height={52} color={colors.grey01} pointer onClick={onRequestClose}>
                        <Icon name="close_16px" color={colors.white01} />
                    </Block>
                </Block>
            }
        >
            {(loadingPage) && <Block width="100%" height="100%" center middle><Loading color={colors.grey01} /></Block>}
            {!!page && <Form
                innerRef={formRef}
                initialValues={page}
                schema={yup.object().shape({
                    title: yup.string().required(),
                    slug: yup.string().required(),
                    content: yup.object().required(),
                    metatags: yup.object().required()
                })}
                onSubmit={(values) => {
                    if (!loadingPageUpdate) {
                        mutatePageUpdate({ variables: values });
                    }
                }}
            >
                {({
                    values,
                    errors,
                    handleSubmit,
                }) => {
                    return (
                        <form onSubmit={handleSubmit} autoComplete="off">
                            <PortletOpenClose title="General data">
                                <Portlet width="100%">
                                    <FormGroup>
                                        <Block width={456}>
                                            <Input Component={TextInput} error={errors.title} name="title" label="Title" disabled />
                                        </Block>
                                        <Block width={356}>
                                            <Input Component={TextInput} error={errors.slug} name="slug" label="Slug" disabled />
                                        </Block>
                                    </FormGroup>
                                </Portlet>
                            </PortletOpenClose>
                            <PortletOpenClose title="Metatags">
                                <FormGroup>
                                    <Block>
                                        <Input Component={TextInput} error={errors.metatags && errors.metatags.title} name="metatags.title" label="Title" />
                                    </Block>
                                    <Block>
                                        <Input Component={TextAreaInput} error={errors.metatags && errors.metatags.description} name="metatags.description" label="Description" />
                                    </Block>
                                    <Block>
                                        <Input Component={TextInput} error={errors.metatags && errors.metatags.keywords} name="metatags.keywords" label="Keywords" />
                                    </Block>
                                </FormGroup>
                            </PortletOpenClose>
                            {getContentStructure(errors)}
                            <PortletOpenClose title="Footer">
                                <FormGroup>
                                    <Block>
                                        <Input Component={TextInput} error={(errors.content && errors.content.footer) && errors.content.footer.slogan1} name="content.footer.slogan1" label="Slogan 1" />
                                    </Block>
                                    <Block>
                                        <Input Component={TextInput} error={(errors.content && errors.content.footer) && errors.content.footer.slogan2} name="content.footer.slogan2" label="Slogan 2" />
                                    </Block>
                                </FormGroup>
                                <FormGroup>
                                    <Block>
                                        <Input Component={TextInput} error={(errors.content && errors.content.footer) && errors.content.footer.rightsLabel} name="content.footer.rightsLabel" label="Rights label" />
                                    </Block>
                                    <Block>
                                        <Input Component={TextInput} error={(errors.content && errors.content.footer) && errors.content.footer.legalLabel} name="content.footer.legalLabel" label="Legal label" />
                                    </Block>
                                    <Block>
                                        <Input Component={TextInput} error={(errors.content && errors.content.footer) && errors.content.footer.followUsLabel} name="content.footer.followUsLabel" label="Follow us label" />
                                    </Block>
                                </FormGroup>
                                <FormGroup title="Social networks">
                                    <Block>
                                        <Input Component={TextInput} error={(errors.content && errors.content.footer) && errors.content.footer.instragram} name="content.footer.instragram" label="Instagram" />
                                    </Block>
                                    <Block>
                                        <Input Component={TextInput} error={(errors.content && errors.content.footer) && errors.content.footer.facebook} name="content.footer.facebook" label="Facebook" />
                                    </Block>
                                    <Block>
                                        <Input Component={TextInput} error={(errors.content && errors.content.footer) && errors.content.footer.linkedin} name="content.footer.linkedin" label="Linkedin" />
                                    </Block>
                                    <Block>
                                        <Input Component={TextInput} error={(errors.content && errors.content.footer) && errors.content.footer.youtube} name="content.footer.youtube" label="Youtube" />
                                    </Block>
                                </FormGroup>
                            </PortletOpenClose>
                        </form>
                    )
                }}
            </Form>}
        </ModalThatSlides>
    );
}