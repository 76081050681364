import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { Block, Button, Form, Icon, Input, Text, TextInput } from '../../components';
import colors from '../../utils/colors';
import * as yup from 'yup';
import gql from "graphql-tag";
import { useCookies } from 'react-cookie';
import { useMutation } from '@apollo/react-hooks';
import { getClientToken, setClientToken } from '../../utils/client';
function Login() {
    const [cookies, setCookie] = useCookies(["backoffice_session"]);
    const history = useHistory();

    const [mutateUserLogin, { loading }] = useMutation(
        gql`
            mutation userLogin($email: String!, $password: String!) {
                userLogin(email: $email, password: $password) {
                    token
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.userLogin) {
                    setClientToken(data.userLogin.token);
                    setCookie("backoffice_session", data.userLogin.token, { path: '/' });
                }
            }
        }
    );

    if (getClientToken() || cookies.backoffice_session) {
        history.push("/dashboard");
        return null;
    }

    return (
        <Container center middle>
            <ButtonBack center middle pointer onClick={() => history.goBack()}>
                <Icon name="arrow_left_20px" />
            </ButtonBack>
            <Center br={16} width={452} height={432} pt={24} pr={24} pb={24} pl={24}>
                <Text size={24} height={32} bold>Login</Text>
                <Form
                    schema={yup.object().shape({
                        email: yup.string().email().required(),
                        password: yup.string().required()
                    })}
                    onSubmit={(values) => {
                        mutateUserLogin({ variables: values });
                    }}
                >
                    {({
                        values,
                        errors,
                        handleSubmit,
                    }) => {
                        return (
                            <form onSubmit={handleSubmit} autoComplete="off">
                                <Block mt={36}>
                                    <Text size={12} height={16} mb={12} bold>EMAIL</Text>
                                    <Input Component={TextInput} name="email" placeholder="Profissional or personal email" />
                                </Block>
                                <Block mt={20}>
                                    <Text size={12} height={16} mb={12} bold>PASSWORD</Text>
                                    <Input Component={TextInput} type="password" name="password" placeholder="Password associated" />
                                </Block>
                                <Block mt={44}>
                                    <Button type="submit" label="Sign in" loading={loading} color={colors.grey01} width="137px" />
                                </Block>
                            </form>
                        )
                    }}
                </Form>
            </Center>
        </Container >
    );
}

const Container = styled(Block)`
    position: relative;
    width: 100%;
    height: 100%;
`;

const Center = styled(Block)`
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
`;

const ButtonBack = styled(Block)`
    position: absolute;
    top: 24px;
    left: 22px;
    width: 52px;
    height: 52px;
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(102, 102, 102, 0.16);
    border-radius: 26px;
    z-index: 99;
`;

export default Login;
