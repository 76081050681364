import React from "react";
import styled from 'styled-components';
import colors from "../../../utils/colors";
import Block from '../../Block';
import Text from '../../Text';

function TextAreaInput(
    {
        label,
        error,
        onChangeValue,
        value = "",
        ...props
    }
) {
    return (
        <Block width="100%">
            <Block>
                <Input
                    value={value}
                    onChange={(e) => !!onChangeValue && onChangeValue(e.target.value)}
                    placeholder={label}
                    {...props}
                />
            </Block>
            {(() => {
                let color = null;
                let text = null;
                if (!!error) { text = typeof error === "string" ? error : null; color = colors.red01; }

                if (!!text) {
                    return (
                        <Block mt={8}>
                            <Text
                                size={11}
                                height={16}
                                color={color}
                            >
                                {error}
                            </Text>
                        </Block>
                    );
                }
            })()}
        </Block>
    );
}

export default React.memo(TextAreaInput);

const Input = styled.textarea`
    background-color: ${colors.grey06};
    max-width: 100%;
    min-height: 172px;
    color: ${colors.grey01};
    font-size: 14px;
    line-height: 24px;
    font-family: "Normal";
    border: 0;
    padding: 16px 24px;
    border-radius: 10px;

    ::placeholder {
        color: ${colors.grey03};
    }
`;