import React, { useRef } from "react";
import styled from 'styled-components';
import colors from "../../../utils/colors";
import Block from '../../Block';
import Icon from "../../Icon";
import Loading from "../../Loading";
import Text from '../../Text';

function UploadInput(
    {
        label,
        loading,
        onChangeValue,
        value = undefined,
        disabled
    }
) {
    const inputRef = useRef(null);
    return (
        <Block width="100%">
            {!!label && <Text size={12} height={16} mb={8} color={colors.grey01}>{label}</Text>}
            <Block style={{ position: "relative" }}>
                {(!loading) && <IconWrapper><Icon name={"upload_20px"} color={colors.grey01} /></IconWrapper>}
                {(!!loading) && <StatusWrapper><Loading color={colors.grey01} /></StatusWrapper>}
                <Input
                    value={value}
                    disabled={disabled}
                    onClick={() => (!disabled && !loading) && inputRef.current.click()}
                >
                    {!value && <Text size={14} height={24} color={colors.grey01}>Attach your files here</Text>}
                    {!!value && <Text size={14} height={24} color={colors.grey01}>{value.name}</Text>}
                </Input>
                <input
                    ref={inputRef}
                    style={{ display: "none" }}
                    type="file"
                    onChange={(e) => !!onChangeValue && onChangeValue(e.target.files[0])}
                />
            </Block>
            {
                (() => {
                    let color = null;
                    let text = null;
                    if (!!loading) { text = typeof loading === "string" ? loading : null; color = colors.grey01; }

                    if (!!text) {
                        return (
                            <Block mt={8}>
                                <Text
                                    size={11}
                                    height={16}
                                    color={color}
                                >
                                    {loading}
                                </Text>
                            </Block>
                        );
                    }
                })()
            }
        </Block >
    );
}

export default React.memo(UploadInput);

const Input = styled.div`
    background-color: ${colors.grey06};
    height: 52px;
    color: ${colors.grey01};
    border: 0;
    padding: 0 48px 0 16px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
`;

const IconWrapper = styled(Block)`
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
`;

const StatusWrapper = styled(Block)`
    position: absolute;
    top: 16px;
    right: 16px;
`;