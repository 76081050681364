import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

@font-face {
  font-family: Normal;
  src: url(${process.env.PUBLIC_URL}/fonts/HelveticaNeueMedium.ttf);
}

@font-face {
  font-family: Bold;
  src: url(${process.env.PUBLIC_URL}/fonts/HelveticaNeueBold.ttf);
}

@font-face {
  font-family: Icons;
  src: url(${process.env.PUBLIC_URL}/fonts/icomoon.ttf);
  src: url(${process.env.PUBLIC_URL}/fonts/icomoon.eot?xekb8d);
  src: url(${process.env.PUBLIC_URL}/fonts/icomoon.eot?xekb8d#iefix) format('embedded-opentype'),
    url(${process.env.PUBLIC_URL}/fonts/icomoon.ttf?xekb8d) format('truetype'),
    url(${process.env.PUBLIC_URL}/fonts/icomoon.woff?xekb8d) format('woff'),
    url(${process.env.PUBLIC_URL}/fonts/icomoon.svg?xekb8d#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'], [class*=" icon-"] {
    font-size: 16px;
  }

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: 'Normal', sans-serif;
}

#root, html, body {
  height: 100%;
  font-size: 14px;
}

body {
  font-family: 'Normal', sans-serif;
  -webkit-font-smoothing: antialiased !important;
}

.slide-pane__content {
  margin: 0;
  padding: 0;
}

.slide-pane_from_bottom {
    height: 100vh;
    margin-top: 0;
}
`;