import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import gql from 'graphql-tag';
import React, { useEffect, useRef, useState } from "react";
import * as yup from 'yup';
import { Block, DropdownInput, Form, FormGroup, Icon, Input, Loading, ModalThatSlides, Portlet, Text, TextAreaInput, TextInput } from '../../../../components';
import colors from "../../../../utils/colors";
import ListBenefits from "../ListBenefits";

export default function ModalUpdate({ packId, isOpen, onRequestClose, onUpdate }) {
    const formRef = useRef(null);
    const [pack, setPack] = useState(null);

    const TYPE_OPTIONS = [{ label: "Subscription", value: "SUBSCRIPTION" }, { label: "Quantity", value: "QUANTITY" }];
    const INTERVAL_OPTIONS = [{ label: "Monthly", value: "MONTHLY" }, { label: "Annually", value: "ANNUALLY" }];

    const [queryPack, { loading: loadingPack }] = useLazyQuery(
        gql`
            query pack($id: ID!) {
                pack(id: $id) {
                    id
                    name
                    description
                    priceDescription
                    benefits
                    type
                    price
                    quantity
                    interval
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.pack) {
                    setPack({
                        ...data.pack,
                        type: !!data.pack.type ? TYPE_OPTIONS.find((item) => item.value === data.pack.type) : undefined,
                        interval: !!data.pack.interval ? INTERVAL_OPTIONS.find((item) => item.value === data.pack.interval) : undefined
                    });
                }
            }
        }
    );

    const [mutatePackUpdate, { loading: loadingPackUpdate }] = useMutation(
        gql`
            mutation packUpdate(
                $id: ID!,
                $name: String!
                $description: String!
                $priceDescription: String!
                $benefits: Json!
                $type: PackType!
                $price: Float!
                $quantity: Int
                $interval: PackInterval
            ) {
                packUpdate(
                    id: $id
                    name: $name
                    description: $description
                    priceDescription: $priceDescription
                    benefits: $benefits
                    type: $type
                    price: $price
                    quantity: $quantity
                    interval: $interval
                ) {
                    id
                    name
                    description
                    priceDescription
                    benefits
                    type
                    price
                    quantity
                    interval
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.packUpdate) {
                    !!onUpdate && onUpdate(data.packUpdate);
                    !!onRequestClose && onRequestClose();
                }
            }
        }
    );

    useEffect(() => {
        if (!isOpen) {
            setPack(null);
        }
    }, [isOpen]);

    useEffect(() => {
        if (!!packId) {
            queryPack({ variables: { id: packId } });
        }
    }, [packId]);

    return (
        <ModalThatSlides
            id="pack-update"
            title="Pack update"
            width="820px"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            actions={
                <Block row>
                    <Block
                        row
                        center
                        pl={16}
                        width={176}
                        height={52}
                        color={colors.grey02}
                        pointer={!loadingPackUpdate}
                        onClick={() => {
                            formRef.current.handleSubmit();
                        }}
                    >
                        {loadingPackUpdate ? <Loading /> : <Text size={14} height={28} color={colors.white01}>Save</Text>}
                    </Block>
                    <Block row center middle width={52} height={52} color={colors.grey01} pointer onClick={onRequestClose}>
                        <Icon name="close_16px" color={colors.white01} />
                    </Block>
                </Block>
            }
        >
            {(loadingPack) && <Block width="100%" height="100%" center middle><Loading color={colors.grey01} /></Block>}
            {!!pack && <Form
                innerRef={formRef}
                initialValues={pack}
                schema={yup.object().shape({
                    name: yup.string().required(),
                    description: yup.string().required(),
                    priceDescription: yup.string().required(),
                    benefits: yup.array().required(),
                    type: yup.object().required(),
                    price: yup.number().nullable(),
                    quantity: yup.string().nullable(),
                    interval: yup.object().nullable()
                })}
                onSubmit={(values) => {
                    if (!loadingPackUpdate) {
                        mutatePackUpdate({
                            variables: {
                                id: packId,
                                ...values,
                                price: parseFloat(values.price),
                                quantity: !!values.quantity ? parseInt(values.quantity) : null,
                                type: values.type.value,
                                interval: !!values.interval ? values.interval.value : null
                            }
                        });
                    }
                }}
            >
                {({
                    errors,
                    handleSubmit,
                }) => {
                    return (
                        <form onSubmit={handleSubmit} autoComplete="off">
                            <Portlet width="100%">
                                <FormGroup>
                                    <Block>
                                        <Input Component={TextInput} error={errors.name} name="name" label="Name" />
                                    </Block>
                                    <Block>
                                        <Input Component={TextAreaInput} error={errors.description} name="description" label="Description" />
                                    </Block>
                                    <Block>
                                        <Input Component={TextInput} error={errors.priceDescription} name="priceDescription" label="Price description" />
                                    </Block>
                                    <Block>
                                        <Input Component={ListBenefits} error={errors.benefits} name="benefits" label="Benefits" />
                                    </Block>
                                    <Block>
                                        <Input Component={DropdownInput} error={errors.type} name="type" label="Type" options={TYPE_OPTIONS} />
                                    </Block>
                                    <Block>
                                        <Input type="number" Component={TextInput} error={errors.price} name="price" label="Price" />
                                    </Block>
                                    <Block>
                                        <Input Component={TextInput} error={errors.quantity} name="quantity" label="Quantity" />
                                    </Block>
                                    <Block>
                                        <Input Component={DropdownInput} error={errors.interval} name="interval" label="Interval" options={INTERVAL_OPTIONS} />
                                    </Block>
                                </FormGroup>
                            </Portlet>
                        </form>
                    )
                }}
            </Form>}
        </ModalThatSlides>
    );
}