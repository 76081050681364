import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { AdvancedTable, Block, Button, Icon, Modal, SearchBar, Text } from '../../components';
import colors from '../../utils/colors';
import ModalUpdate from './components/ModalUpdate';

function Clients() {
    const [clients, setClients] = useState({ edges: [], pageInfo: { hasNextPage: false } });
    const [search, setSearch] = useState("");
    const [modalUpdateId, setModalUpdateId] = useState(null);
    const [isModalUpdateOpen, setIsModalUpdateOpen] = useState(false);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [modalDeleteId, setModalDeleteId] = useState(null);

    const paginate = useRef(false);

    const [queryClients, { loading: loadingClients }] = useLazyQuery(
        gql`
            query clients($first: Int, $after: String, $search: String) {
                clients(first: $first, after: $after, search: $search) {
                    edges {
                        cursor
                        node {
                            id
                            name
                            company
                        }
                    }
                    pageInfo {
                        startCursor
                        endCursor
                        hasNextPage
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.clients) {
                    setClients({
                        edges: paginate.current ? [...clients.edges, ...data.clients.edges] : data.clients.edges,
                        pageInfo: data.clients.pageInfo
                    });
                    paginate.current = false;
                }
            }
        }
    );

    const [mutateClientDelete, { loading: loadingClientDelete }] = useMutation(
        gql`
            mutation clientDelete($id: ID!) {
                clientDelete(id: $id) {
                    id 
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.clientDelete) {
                    const index = clients.edges.findIndex((edge) => edge.cursor == data.clientDelete.id);
                    if (index !== -1) {
                        clients.edges.splice(index, 1);
                        setIsModalDeleteOpen(false);
                        setModalDeleteId(null);
                        setClients(...clients);
                    }
                }
            }
        }
    );

    const requestClients = (params = { next: false }) => {
        const { first, after, next } = params;

        if (next) {
            paginate.current = true;
            after = clients.edges[clients.edges.length - 1].cursor
        }

        queryClients({
            variables: {
                first: first || 20,
                after: after || undefined,
                search: search !== "" ? search : undefined
            }
        });
    }

    useEffect(() => {
        requestClients();
    }, [search]);

    return (
        <>
            <Block height="100%" style={{ overflow: "hidden" }}>
                <Block row>
                    <Block flex>
                        <SearchBar value={search} onChange={setSearch} />
                    </Block>
                </Block>
                <Container flex style={{ overflow: "hidden" }}>
                    <Block height="calc(100% - 56px)" pt={24}>
                        <AdvancedTable
                            hasMore={clients.pageInfo.hasNextPage}
                            next={() => requestClients({ next: true })}
                            emptyLabel="No clients"
                            columns={[
                                {
                                    label: "Name",
                                    value: "name",
                                },
                                {
                                    label: "Company",
                                    value: "company"
                                },
                                {
                                    label: "",
                                    value: "actions"
                                }
                            ]}
                            rows={clients.edges.map((edge) => {
                                return ({
                                    name: edge.node.name,
                                    company: edge.node.company,
                                    actions: <Block row right mr={-16} pointer>
                                        <Block row height={52} pl={16} pr={16} center style={{ borderLeft: `1px solid ${colors.grey05}` }} onClick={() => { setIsModalUpdateOpen(true); setModalUpdateId(edge.node.id); }}>
                                            <Icon name="file_text_16px" />
                                            <Text color={colors.grey01} size={14} height={20} ml={8}>Edit</Text>
                                        </Block>
                                        <Block row width={52} height={52} pl={16} pr={16} center style={{ borderLeft: `1px solid ${colors.grey05}` }} onClick={() => { setIsModalDeleteOpen(true); setModalDeleteId(edge.node.id); }}>
                                            <Icon name="trash_16px" />
                                        </Block>
                                    </Block>
                                });
                            })}
                        />
                    </Block>
                </Container>
            </Block>
            
            <ModalUpdate
                clientId={modalUpdateId}
                isOpen={isModalUpdateOpen}
                onRequestClose={() => {
                    setIsModalUpdateOpen(false);
                    setModalUpdateId(null);
                }}
                onUpdate={(client) => {
                    const index = clients.edges.findIndex((edge) => edge.node.id === client.id);
                    clients.edges[index].node = { ...clients.edges[index].node, ...client };
                    setClients(clients);
                }}
            />

            <Modal
                isOpen={isModalDeleteOpen}
                onRequestClose={() => setIsModalDeleteOpen(false)}
            >
                <Block center>
                    <Icon size={32} name="warning_32px" color={colors.red01} />
                    <Text size={16} height={24} color={colors.red01} mt={12}>Warning</Text>
                    <Text size={14} height={17} color={colors.grey01} center mt={4} mb={40}>This action will delete the user and all associated data, this action will be permanent. Are you sure that you to proceed?</Text>
                </Block>
                <Block>
                    <Button loading={loadingClientDelete} label="Delete user" color={colors.red01} width="100%" onClick={() => mutateClientDelete({ variables: { id: modalDeleteId } })} />
                </Block>
            </Modal>
        </>
    );
}

const Container = styled(Block)`
    padding: 32px 20px;
`;

export default Clients;
