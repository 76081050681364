import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { AdvancedTable, Block, Button, Icon, Modal, SearchBar, Text } from '../../components';
import colors from '../../utils/colors';
import ModalCreate from './components/ModalCreate';
import ModalUpdate from './components/ModalUpdate';

function Packs() {
    const [packs, setPacks] = useState({ edges: [] });
    const [search, setSearch] = useState("");
    const [modalUpdateId, setModalUpdateId] = useState(null);
    const [isModalUpdateOpen, setIsModalUpdateOpen] = useState(false);
    const [isModalCreateOpen, setIsModalCreateOpen] = useState(false);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [modalDeleteId, setModalDeleteId] = useState(null);

    const paginate = useRef(false);

    const [queryPacks, { loading: loadingPacks }] = useLazyQuery(
        gql`
            query packs($first: Int, $after: String, $search: String) {
                packs(first: $first, after: $after, search: $search) {
                    edges {
                        cursor
                        node {
                            id
                            name
                            description
                            priceDescription
                            benefits
                            type
                            price
                            quantity
                            interval
                        }
                    }
                    pageInfo {
                        startCursor
                        endCursor
                        hasNextPage
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.packs) {
                    setPacks({
                        edges: paginate.current ? [...packs.edges, ...data.packs.edges] : data.packs.edges,
                        pageInfo: data.packs.pageInfo
                    });
                    paginate.current = false;
                }
            }
        }
    );

    const [mutateUserDelete, { loading: loadingUserDelete }] = useMutation(
        gql`
            mutation packDelete($id: ID!) {
                packDelete(id: $id) {
                    id 
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.packDelete) {
                    const index = packs.edges.findIndex((edge) => edge.cursor == data.packDelete.id);
                    if (index !== -1) {
                        packs.edges.splice(index, 1);
                        setIsModalDeleteOpen(false);
                        setModalDeleteId(null);
                        setPacks(...packs);
                    }
                }
            }
        }
    );

    const requestPacks = (params = { next: false }) => {
        const { first, after, next } = params;

        if (next) {
            paginate.current = true;
        }

        queryPacks({
            variables: {
                first: first || 20,
                after: after || undefined,
                search: search !== "" ? search : undefined
            }
        });
    }

    useEffect(() => {
        requestPacks();
    }, []);

    useEffect(() => {
        requestPacks();
    }, [search]);

    return (
        <>
            <Block height="100%" style={{ overflow: "hidden" }}>
                <Block row>
                    <Block flex>
                        <SearchBar value={search} onChange={setSearch} />
                    </Block>
                    <Button style={{ borderRadius: 0 }} label="Create pack" color={colors.grey01} onClick={() => { setIsModalCreateOpen(true); }} />
                </Block>
                <Container flex style={{ overflow: "hidden" }}>
                    <Block height="calc(100% - 56px)" pt={24}>
                        <AdvancedTable
                            emptyLabel="No packs"
                            columns={[
                                {
                                    label: "Name",
                                    value: "name",
                                },
                                {
                                    label: "",
                                    value: "actions"
                                }
                            ]}
                            rows={packs.edges.map((edge) => {
                                return ({
                                    name: edge.node.name,
                                    email: edge.node.email,
                                    actions: <Block row right mr={-16} pointer>
                                        <Block row height={52} pl={16} pr={16} center style={{ borderLeft: `1px solid ${colors.grey05}` }} onClick={() => { setIsModalUpdateOpen(true); setModalUpdateId(edge.node.id); }}>
                                            <Icon name="file_text_16px" />
                                            <Text color={colors.grey01} size={14} height={20} ml={8}>Edit</Text>
                                        </Block>
                                        <Block row width={52} height={52} pl={16} pr={16} center style={{ borderLeft: `1px solid ${colors.grey05}` }} onClick={() => { setIsModalDeleteOpen(true); setModalDeleteId(edge.node.id); }}>
                                            <Icon name="trash_16px" />
                                        </Block>
                                    </Block>
                                });
                            })}
                        />
                    </Block>
                </Container>
            </Block>

            <ModalCreate
                isOpen={isModalCreateOpen}
                onRequestClose={() => {
                    setIsModalCreateOpen(false);
                }}
                onCreate={(_pack) => {
                    requestPacks();
                }}
            />

            <ModalUpdate
                packId={modalUpdateId}
                isOpen={isModalUpdateOpen}
                onRequestClose={() => {
                    setIsModalUpdateOpen(false);
                    setModalUpdateId(null);
                }}
                onUpdate={(pack) => {
                    const index = packs.edges.findIndex((edge) => edge.node.id === pack.id);
                    packs.edges[index].node = { ...packs.edges[index].node, ...pack };
                    setPacks(packs);
                }}
            />

            <Modal
                isOpen={isModalDeleteOpen}
                onRequestClose={() => setIsModalDeleteOpen(false)}
            >
                <Block center>
                    <Icon size={32} name="warning_32px" color={colors.red01} />
                    <Text size={16} height={24} color={colors.red01} mt={12}>Warning</Text>
                    <Text size={14} height={17} color={colors.grey01} center mt={4} mb={40}>This action will delete the pack and all associated data, this action will be permanent. Are you sure that you to proceed?</Text>
                </Block>
                <Block>
                    <Button loading={loadingUserDelete} label="Delete pack" color={colors.red01} width="100%" onClick={() => mutateUserDelete({ variables: { id: modalDeleteId } })} />
                </Block>
            </Modal>
        </>
    );
}

const Container = styled(Block)`
    padding: 32px 20px;
`;

export default Packs;
