import React from "react";
import styled from "styled-components";
import colors from "../../utils/colors";
import Block from "../Block";
import Loading from "../Loading";

export default function Button(
    {
        label,
        icon,
        color = colors.grey01,
        loading = false,
        disabled,
        size = 52,
        width,
        children,
        ...props
    }
) {
    let hover = color;

    if (color === colors.grey01) {
        hover = colors.grey02;
    } else if (color === colors.yellow01) {
        hover = colors.yellow03;
    }

    return (
        <Btn
            type="button"
            width={width}
            disabled={!!disabled || !!loading}
            loading={!!loading}
            color={color}
            hover={hover}
            size={size}
            {...props}
        >
            {(!!icon && !loading) && <Block mr={(!!icon && !!label) ? 8 : 0}><Icon color={colors.white01} className={`icon-${icon}`} /></Block>}
            {(!!loading && !!label) && "Loading..."}
            {(!loading && !!label) && label}
            {loading ? <Block ml={!!label ? 8 : 0}><Loading color={colors.white01} /></Block> : children}
        </Btn>
    );
}

const Icon = styled.span`
    ::before {
        color: ${({ color }) => color};
    }
`;

const Btn = styled.button`
    font-family: "Normal";
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    width: ${({ width }) => !!width ? width : "fit-content"};
    border-radius: 26px;
    border: none;

    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;

    background-color: ${({ color }) => color};
    color: ${colors.white01};

    height: ${({ size }) => size}px;
    padding: 14px 32px;

    :disabled {
        cursor: auto;
    }
    
    :hover {
        background-color: ${({ hover }) => hover};
    }
`;